import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { RenderLink } from "../../components/recent-posts"
const PostArchive = ({ data, location }) => {
  const archiveData = useStaticQuery(graphql`
    query {
      allMdx(sort: { fields: frontmatter___date, order: DESC }) {
        edges {
          node {
            id
            frontmatter {
              title
              categories
              date
              tags
              description
              slug
              external_link
            }
          }
        }
      }
    }
  `)

  const formatDate = (dateStr) => {
    //TODO: this
  };

  return (
    <Layout location={location}>
      <SEO title="Post Archive" />
      <div className="container">
        <div className="main-content__container">
          <div className="main-content__body">
            <h1 className="main-content__title">Post Archive</h1>
            <div className="main-content__content">
              <p>Apologies for the mess; the blog is still a work-in-progress. Technically-minded entries can also be red on <a href="https://dev.to/heyitsstacey/">DEV</a>.</p>
              <ul className="post-archive__list">
                {archiveData.allMdx.edges.map(post => (
                  <li
                    className="post-archive__list-item"
                    key={post.node.frontmatter.slug}
                  >
                    <RenderLink
                      slug={post.node.frontmatter.slug}
                      classes="posts__link"
                      externalLink={post.node.frontmatter.external_link}
                    >
                      <h4>{post.node.frontmatter.title}</h4>
                    </RenderLink>
                    <p
                      className="posts__description"
                      dangerouslySetInnerHTML={{
                        __html: post.node.frontmatter.description,
                      }}
                    /> 
                    {/* <span>{post.node.frontmatter.date}</span> */}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PostArchive

import React from "react"
import classnames from "classnames"
import { useStaticQuery, graphql, Link } from "gatsby"
import ExternalIcon from "./external-icon"

const propTypes = {}

export const RenderLink = props => {
  const { externalLink, slug, children, classes } = props
  const linkClasses = classnames("", {
    [classes]: classes,
  })
  if (externalLink) {
    return (
      <a
        href={externalLink}
        className={linkClasses}
        rel="noopener noreferrer"
        target="_blank"
      >
        {children}
        {/* <ExternalIcon /> */}
      </a>
    )
  } else {
    return (
      <Link to={`/posts/${slug}`} className={linkClasses}>
        {children}
      </Link>
    )
  }
}
const RecentPosts = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        limit: 3
        filter: { frontmatter: { type: { eq: "post" } } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              categories
              date
              tags
              description
              slug
              external_link
            }
          }
        }
      }
    }
  `)

  const renderPost = post => {
    const { slug, external_link, title, description, tags, date } = post
    return (
      <div key={slug}>
        <RenderLink
          slug={slug}
          classes="posts__link"
          externalLink={external_link}
        >
          <h4>{title}</h4>
        </RenderLink>
        <p
          className="posts__description"
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </div>
    )
  }
  return (
    <div className="posts">
      <h3 className="posts__heading">Ideas</h3>
      {data.allMdx.edges.map(post => renderPost(post.node.frontmatter))}
      <RenderLink 
      slug="" classes="posts__more-button">
        View more posts
      </RenderLink>
      
    </div>
  )
}

export default RecentPosts
